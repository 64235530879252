import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  EmailField,
} from 'react-admin'

const ReminderShow = props =>
  <Show { ...props }>
    <TabbedShowLayout>
      <Tab icon={ <DescriptionIcon /> }
        label="Résumé"
      >
        <TextField
          label="Identifiant"
          source="id"
        />
        <TextField
          label="Nom"
          source="user.lastname"
        />
        <TextField
          label="Prénom"
          source="user.firstname"
        />
        <EmailField
          label="Email"
          source="user.email"
        />
        <TextField
          label="Nom d'utilisateur"
          source="user.username"
        />
        <TextField
          label="Nb de jours avant expiration"
          source="days"
        />
        <DateField
          label="Date d'envoi"
          source="createdAt"
        />
      </Tab>
    </TabbedShowLayout>
  </Show>

export default ReminderShow
