import { GET_LIST, GET_ONE, GET_MANY, UPDATE, CREATE } from 'react-admin'
import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapDataToPlanInput } from 'data-mapper/plan'
import { mapGetManyVariables, mapGetListVariables } from 'data-mapper'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
      query {
        allPlans {
          id
          title
          description
          appleId
          googleId
          stripeId
          product {
            id
            title
          }
          taxRate {
            id
            name
          }
          amount
          interval
          createdAt
          history
        }
      }
    `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allPlans,
    total: data.allPlans.length,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
      query ($id: ID!) {
        plan(id: $id) {
          id
          title
          description
          appleId
          googleId
          stripeId
          product {
            id
            title
          }
          taxRate {
            id
            name
          }
          amount
          interval
          createdAt
          history
        }
      }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.plan,
  }))

const create = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($plan: PlanInput!) {
        createPlan(plan: $plan) {
          id
          title
          description
          appleId
          googleId
          stripeId
          product {
            id
            title
          }
          taxRate {
            id
            name
          }
          amount
          interval
          createdAt
          history
        }
      }
    `,
    variables: {
      plan: mapDataToPlanInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.createPlan,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($id: ID!, $plan: PlanInput!) {
        updatePlan(id: $id, plan: $plan) {
          id
          title
          description
          appleId
          googleId
          stripeId
          product {
            id
            title
          }
          taxRate {
            id
            name
          }
          amount
          interval
          createdAt
          history
        }
      }
    `,
    variables: {
      id: params.id,
      plan: mapDataToPlanInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.updatePlan,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_MANY: return getList(type, params)
    case GET_ONE: return getOne(params)
    case CREATE: return create(params)
    case UPDATE: return update(params)
    default: return new Promise()
  }
}
