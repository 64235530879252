import { GET_LIST, GET_ONE, CREATE, UPDATE, GET_MANY } from 'react-admin'
import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapDataToPharmacyInput } from 'data-mapper/pharmacy'
import { mapGetManyVariables, mapGetListVariables } from 'data-mapper'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
        query ($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: PharmacyFilter) {
            allPharmacies(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                id
                name
                address
                zipCode
                city
                country
                organization
                createdAt
                owner {
                    id
                    username
                }
                users {
                    id
                    username
                    email
                    lastname
                    firstname
                }
            }
            _allPharmaciesMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                count
            }
        }
    `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allPharmacies,
    total: data._allPharmaciesMeta.count,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
        query ($id: ID!) {
            pharmacy(id: $id) {
                id
                name
                address
                zipCode
                city
                country
                organization
                createdAt
                owner {
                    id
                    username
                }
                users {
                    id
                    username
                    email
                    lastname
                    firstname
                }
            }
        }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.pharmacy,
  }))

const create = params => apolloClient
  .mutate({
    mutation: gql`
        mutation ($pharmacy: PharmacyInput!) {
            createPharmacy(pharmacy: $pharmacy) {
                id
                name
                address
                zipCode
                city
                country
                organization
            }
        }
    `,
    variables: {
      pharmacy: mapDataToPharmacyInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.createPharmacy,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
        mutation ($id: ID!, $pharmacy: PharmacyInput!) {
            updatePharmacy(id: $id, pharmacy: $pharmacy) {
                id
                name
                address
                zipCode
                city
                country
                organization
            }
        }
    `,
    variables: {
      id: params.id,
      pharmacy: mapDataToPharmacyInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.updatePharmacy,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_MANY: return getList(type, params)
    case GET_ONE: return getOne(params)
    case CREATE: return create(params)
    case UPDATE: return update(params)
    default: return new Promise()
  }
}
