import TimeSpentField from 'components/form-field/TimeSpentField'
import React, { useEffect, useState } from 'react'
import {
  ArrayField,
  Datagrid,
  Labeled,
  NumberField,
  TextField,
  useDataProvider,
  useRecordContext,
} from 'react-admin'

const UserStatistics = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [userStatistics, setUserStatistics] = useState(null)

  useEffect(() => {
    const fetchUserStatistics = async () => {
      await dataProvider.getOne('user', {
        id: record.id ,
        query: { name: 'GET_USER_STATISTICS' }},
      ).then(({ data }) => {
        setUserStatistics(data)
      })
    }

    if (record) {
      fetchUserStatistics()
    }
  }, [dataProvider, record])

  if (!userStatistics) return null

  return (
    <div style={ {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5em',
    } }>
      <Labeled label="Score cumulé">
        <NumberField
          record={ userStatistics }
          source="calculatedSumScore"
        />
      </Labeled>
      <Labeled label="Score moyen">
        <NumberField
          record={ userStatistics }
          source="userStats.avgScore"
        />
      </Labeled>
      <Labeled label="Taux de progression">
        <NumberField
          record={ userStatistics }
          source="userStats.progressionScore"
        />
      </Labeled>
      <Labeled label="Quiz à faire">
        <ArrayField
          record={ userStatistics }
          source="todoQuizzes"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Titre"
              sortable={ false }
              source="title"
            />
          </Datagrid>
        </ArrayField>
      </Labeled>
      <Labeled label="Quiz à refaire">
        <ArrayField
          record={ userStatistics }
          source="redoQuizzes"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Titre"
              sortable={ false }
              source="title"
            />
          </Datagrid>
        </ArrayField>
      </Labeled>
      <Labeled label="Quiz validés">
        <ArrayField
          record={ userStatistics }
          source="doneQuizzes"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Titre"
              sortable={ false }
              source="title"
            />
          </Datagrid>
        </ArrayField>
      </Labeled>
      <Labeled label="Quiz faits par semaine">
        <ArrayField
          record={ userStatistics }
          source="userStats.weekQuizzes"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Nombre"
              sortable={ false }
              source="count"
            />
            <TextField
              label="Semaine"
              sortable={ false }
              source="week"
            />
          </Datagrid>
        </ArrayField>
      </Labeled>
      <Labeled label="Score final quiz à refaire">
        <ArrayField
          record={ userStatistics }
          source="redoQuizzes"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Quiz"
              sortable={ false }
              source="title"
            />
            <NumberField
              label="Score au premier passage"
              sortable={ false }
              source="initialSession.totalScore"
            />
            <TimeSpentField
              label="Temps au premier passage"
              sortable={ false }
              source="initialSession.timeSpent"
            />
            <NumberField
              label="Score au dernier passage"
              sortable={ false }
              source="lastSession.totalScore"
            />
            <TimeSpentField
              label="Temps au dernier passage"
              sortable={ false }
              source="lastSession.timeSpent"
            />
          </Datagrid>
        </ArrayField>
      </Labeled>
      <Labeled label="Score final quiz validés">
        <ArrayField
          record={ userStatistics }
          source="doneQuizzes"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Quiz"
              sortable={ false }
              source="title"
            />
            <NumberField
              label="Score au premier passage"
              sortable={ false }
              source="initialSession.totalScore"
            />
            <TimeSpentField
              label="Temps au premier passage"
              sortable={ false }
              source="initialSession.timeSpent"
            />
            <NumberField
              label="Score au dernier passage"
              sortable={ false }
              source="lastSession.totalScore"
            />
            <TimeSpentField
              label="Temps au dernier passage"
              sortable={ false }
              source="lastSession.timeSpent"
            />
          </Datagrid>
        </ArrayField>
      </Labeled>
    </div>
  )
}

export default UserStatistics
