import React from 'react'
import { CreateButton, ExportButton, TopToolbar } from 'react-admin'

const DefaultListActions = () =>
  <TopToolbar>
    <CreateButton />
    <ExportButton/>
  </TopToolbar>

export default DefaultListActions
