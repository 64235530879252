import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin'

const PharmacyList = props =>
  <List
    { ...props }
    pagination={ null }
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Nom"
        sortable={ false }
        source="name"
      />
      <TextField
        label="Adresse"
        sortable={ false }
        source="address"
      />
      <TextField
        label="Code postal"
        sortable={ false }
        source="zipCode"
      />
      <TextField
        label="Ville"
        sortable={ false }
        source="city"
      />
      <TextField
        label="Pays"
        sortable={ false }
        source="country"
      />
      <TextField
        label="Groupement"
        sortable={ false }
        source="organization"
      />
      <DateField
        label="Date de creation"
        sortable={ false }
        source="createdAt"
      />
      <TextField
        label="Pharmacien titulaire"
        sortable={ false }
        source="owner.username"
      />
    </Datagrid>
  </List>

export default PharmacyList
