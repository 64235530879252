import CustomToolbar from 'components/toolbar'
import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin'

const EditSubscription = props =>
  <Edit { ...props }>
    <SimpleForm toolbar={ <CustomToolbar /> }>
      <TextInput
        label="Url de l'abonnement"
        source="urlSource"
        fullWidth
      />
    </SimpleForm>
  </Edit>

export default EditSubscription
