import React from 'react'
import { useMutation } from '@apollo/client'
import { ACTIVATE_USER } from 'graphql/queries/users'
import { Button, useRefresh } from 'react-admin'
import PropTypes from 'prop-types'

const ActiveUserEmailButton = ({ userId }) => {
  const [activeUser, { loading }] = useMutation(ACTIVATE_USER)
  const refresh = useRefresh()

  const handleClick = () => {
    if (userId) {
      activeUser({ variables: { id: userId }})
        .then(() => refresh())
    } else {
      console.error('Error while activating user : user not found')
    }
  }

  return (
    <Button
      label="Forcer l'activation de l'email de l'utilisateur"
      onClick={ handleClick }
      disabled={ loading }
    />
  )
}

ActiveUserEmailButton.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default ActiveUserEmailButton
