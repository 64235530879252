import { GET_LIST, GET_MANY } from 'react-admin'
import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapGetManyVariables, mapGetListVariables } from 'data-mapper'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
      query ($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserFilter) {
          allRankings(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
              id
              firstname
              lastname
              username
              job
              email
              badge
              calculatedSumScore
          }
          _allUsersMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
              count
          }
      }
  `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allRankings,
    total: data._allUsersMeta.count,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_MANY: return getList(type, params)
    default: return new Promise()
  }
}
