export const mapDataToAddressInput = ({
  address,
  address2,
  zipCode,
  city,
  country,
}) => ({
  address,
  address2,
  zipCode,
  city,
  country,
})
