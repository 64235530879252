import CustomAddItemButton from 'components/button/CustomAddItemButton'
import React from 'react'
import {
  Create,
  ArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  required,
} from 'react-admin'
import { validateQuiz } from './validation'

const CreateQuiz = props =>
  <Create { ...props }>
    <SimpleForm validate={ validateQuiz }>
      <TextInput
        label="Titre du quiz"
        source="title"
        validate={ required() }
        fullWidth
        helperText="Titre limité a 255 caractères"
      />

      <ReferenceInput
        label="Theme"
        reference="theme"
        source="theme.id"
      >
        <SelectInput optionText="title" validate={ required() }/>
      </ReferenceInput>

      <NumberInput
        label="Numéro"
        source="number"
      />

      <DateTimeInput
        label="Date de publication"
        source="releaseDate"
        validate={ required() }
      />

      <ArrayInput
        source="questions"
        fullWidth
      >
        <SimpleFormIterator fullWidth getItemLabel={ index => `Question ${index + 1} :` }>
          <TextInput
            label="Sujet"
            source="topic"
            validate={ required() }
            helperText="Intitulé limité a 255 caractères"
          />

          <TextInput
            label="Intitulé de la question"
            source="title"
            validate={ required() }
            fullWidth
            helperText="Intitulé limité a 255 caractères"
          />

          <SelectInput
            choices={ [
              { id: 'SINGLE_CHOICE', name: 'Choix unique' },
              { id: 'MULTI_CHOICES', name: 'Choix multiple' },
            ] }
            label="Type"
            source="type"
            validate={ required() }
          />

          <ArrayInput
            label="Réponses"
            source="answers"
            fullWidth
          >
            <SimpleFormIterator addButton={ <CustomAddItemButton /> } fullWidth getItemLabel={ index => `Réponse ${index + 1} :` }>
              <TextInput
                label="Intitulé de la réponse"
                source="title"
                validate={ required() }
                fullWidth
                helperText="Intitulé limité a 255 caractères"
              />
              <BooleanInput
                label="Valide"
                source="valid"
                defaultValue={ false }
              />
              <BooleanInput
                label="Grave"
                source="important"
                defaultValue={ false }
              />
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput
            label="Feedback"
            source="feedback"
            validate={ required() }
            fullWidth
            multiline
          />

        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>

export default CreateQuiz
