import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  BooleanField,
  EmailField,
} from 'react-admin'

const CommentShow = props =>
  <Show { ...props }>
    <TabbedShowLayout>
      <Tab
        icon={ <DescriptionIcon /> }
        label="Résumé"
      >
        <TextField
          label="Identifiant"
          source="id"
        />
        <TextField
          label="Remarque"
          source="content"
        />
        <DateField
          label="Date de publication"
          source="createdAt"
        />
        <TextField
          label="Signalé par"
          source="user.username"
        />
        <EmailField
          label="Email"
          source="user.email"
        />
        <TextField
          label="Quiz"
          source="question.quiz.title"
        />
        <TextField
          label="Position de la question dans le quiz"
          source="question.position"
        />
        <TextField
          label="Feed-back"
          source="question.feedback"
        />
        <BooleanField
          label="Remarque verifiée"
          source="checking"
        />
      </Tab>
    </TabbedShowLayout>
  </Show>

export default CommentShow
