import React from 'react'
import {
  Create,
  SimpleForm,
  required,
} from 'react-admin'
import { FormatButtons ,RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text'
import { styled } from '@mui/material/styles'

const DailyQuestionCreate = props =>
  <Create redirect="list" { ...props }>
    <SimpleForm>
      <RichTextInput
        label="Intitulé"
        source="title"
        toolbar={ <Root>
          <RichTextInputToolbar>
            <FormatButtons />
          </RichTextInputToolbar>
        </Root> }
        validate={ required() }
        fullWidth
      />
      <RichTextInput
        label="Answer"
        source="answer"
        toolbar={ <Root>
          <RichTextInputToolbar>
            <FormatButtons />
          </RichTextInputToolbar>
        </Root> }
        validate={ required() }
        fullWidth
      />
    </SimpleForm>
  </Create>

const Root = styled('div')(() => ({
  alignSelf: 'flex-start',
  button: {
    display: 'none',
  },
  'button[aria-label="Bold"]': {
    display: 'block',
    borderRadius: 0,
  },
}))

export default DailyQuestionCreate
