import { gql } from '@apollo/client'

export const ALL_JOBS = gql`
  query {
    allJobs {
      title
    }
  }
`

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      stripeId
      gender
      firstname
      lastname
      username
      email
      avatar
      type
      rpps
      rppsProof
      job
      activatedAt
      personalPhone
      address {
        address
        address2
        zipCode
        city
        country
      }
      billingAddress {
        address
        address2
        zipCode
        city
        country
      }
      badge
      createdAt
      organization
      pharmacy {
        id
        name
      }
      unlockedQuizs {
        quiz {
          id
          number
          title
        }
      }
      dpcAccess {
        startDate
        endDate
        isCompleted
        isExpired
      }
      switchToken {
        authToken
      }
      subscriptions {
        id
        quantity
        startDate
        endDate
        platform
        urlSource
        plan {
          id
          title
        }
        user {
          firstname
          lastname
          email
        }
      }
    }
  }
`

export const GET_USER_STATISTICS = gql`
  query GetUserStatistics($id: ID!) {
    user(id: $id) {
      id
      calculatedSumScore
      badge
      doneQuizzes {
        id
        title
        initialSession(userId: $id) {
          id
          totalScore
          timeSpent
        }
        lastSession(userId: $id) {
          id
          totalScore
          timeSpent
        }
      }
      redoQuizzes {
        id
        title
        initialSession(userId: $id) {
          id
          totalScore
          timeSpent
        }
        lastSession(userId: $id) {
          id
          totalScore
          timeSpent
        }
      }
      todoQuizzes {
        id
        title
      }
      userStats {
        avgScore
        progressionScore
        weekQuizzes {
          count
          week
        }
      }
      unlockedQuizs {
        quiz {
          id
          number
          title
        }
      }
      dpcAccess {
        startDate
        endDate
        isCompleted
        isExpired
      }
    }
  }
`

export const ACTIVATE_USER = gql`
  mutation activeUser($id: ID!) {
    activeUser(id: $id) {
      id
    }
  }
`
