import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'

const TimeSpentField = ({ source }) => {
  const record = useRecordContext()
  const value = useMemo(() => {
    if (record === null) return null

    return source.split('.').reduce((acc, key) => {
      return acc[key]
    } , record)
  })

  if (!record) return null

  return (
    <span>{timeFormatter(value)}</span>
  )
}

const timeFormatter = time => {
  let milliseconds = parseInt((time % 1000) / 100)
  let seconds = parseInt((time / 1000) % 60)
  let minutes = parseInt((time / (1000 * 60)) % 60)
  let hours = parseInt((time / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds + '.' + milliseconds
}

TimeSpentField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

TimeSpentField.defaultProps = {
  addLabel: true,
}

export default TimeSpentField
