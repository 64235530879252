import React from 'react'
import { Button, CreateButton, ExportButton, TopToolbar, useRedirect } from 'react-admin'
import ContentAdd from '@mui/icons-material/Add'

const icon = <ContentAdd />

const ListActions = () => {
  const redirect = useRedirect()

  return (
    <TopToolbar>
      <Button
        onClick={ () => redirect('/subscription/create-internal') }
        label="Create internal"
      >
        {icon}
      </Button>
      <CreateButton />
      <ExportButton/>
    </TopToolbar>
  )
}

export default ListActions
