import CustomToolbar from 'components/toolbar'
import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  regex,
} from 'react-admin'

const EditPharmacy = props =>
  <Edit { ...props }>
    <SimpleForm toolbar={ <CustomToolbar /> }>
      <TextInput
        label="Nom de la pharmacie"
        source="name"
        validate={ required() }
        fullWidth
      />
      <TextInput
        label="Adresse de la pharmacie"
        source="address"
        fullWidth
      />
      <TextInput
        label="Code postal de la pharmacie"
        source="zipCode"
        validate={ [required(), regex(/^\d{5}$/)] }
        fullWidth
      />
      <TextInput
        label="Ville de la pharmacie"
        source="city"
        fullWidth
      />
      <TextInput
        label="Pays de la pharmacie"
        source="country"
        fullWidth
      />
      <TextInput
        label="Groupement"
        source="organization"
        fullWidth
      />
    </SimpleForm>
  </Edit>

export default EditPharmacy

