import React, { useState, useCallback, useMemo } from 'react'
import { useRecordContext } from 'react-admin'
import { useCookies } from 'react-cookie'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'

const LogAsButton = () => {
  const record = useRecordContext()
  const [, setCookie] = useCookies(['switchToken'])
  const [open, setOpen] = useState(false)
  const isRecordActivated = useMemo(() => null !== record?.activatedAt, [record])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmLogAs = useCallback((event) => {
    event.stopPropagation()
    const switchToken = record.switchToken?.authToken
    if (switchToken) {
      setCookie('switchToken', switchToken, { path: '/', domain: process.env.REACT_APP_DOMAIN })
      window.open(process.env.REACT_APP_LOGIN_URL, '_blank')
    } else {
      console.error('Erreur: Le token d\'échange n\'est pas reconnu pour cet utilisateur')
    }
    handleClose()
  }, [record, setCookie, handleClose])

  if (!record) {
    return (
      <Button variant="contained" size="small" disabled>
        Fonctionnalité &ldquo;Se connecter en tant que&ldquo; en chargement...
      </Button>
    )
  }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        style={ { margin: '0.5rem' } }
        onClick={ handleClickOpen }
        disabled={ !isRecordActivated }
      >
        Se connecter en tant que cet utilisateur
      </Button>
      <Dialog open={ open } onClose={ handleClose }>
        <DialogTitle style={ { textAlign: 'center' } }>Confirmation</DialogTitle>
        <DialogContent style={ { textAlign: 'center' } }>
          <DialogContentText>
            Êtes-vous sûr de vouloir vous connecter en tant que
            {' '}
            <strong>{record.username}</strong>
            {' '}
            ?
            <br/>
            Cette connexion sera effective pendant 30 minutes ou jusqu&apos;à ce que vous vous déconnectiez.
            <br/>
            En confirmant cette action, vous serez redirigé vers le site.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={ { textAlign: 'center' } }>
          <Button onClick={ handleClose }>Annuler</Button>
          <Button onClick={ handleConfirmLogAs } color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default LogAsButton
