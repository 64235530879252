import { GET_LIST, GET_ONE, GET_MANY, CREATE, UPDATE } from 'react-admin'
import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapDataToThemeInput } from 'data-mapper/theme'
import { mapGetManyVariables, mapGetListVariables } from 'data-mapper'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
      query ($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: ThemeFilter) {
        allThemes(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
          id
          title
        }
        _allThemesMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
          count
        }
      }
    `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allThemes,
    total: data._allThemesMeta.count,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
      query ($id: ID!) {
        theme(id: $id) {
          id
          title
          essential {
            sections {
              id
              title
              content
            }
            bibliography {
              id
              title
              description
              position
              link
            }
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.theme,
  }))

const create = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($title: String!) {
        createTheme(title: $title) {
          id
        }
      }
    `,
    variables: {
      title: params.data.title,
    },
  })
  .then(({ data }) => ({
    data: data.createTheme,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($id: ID!, $theme: ThemeInput!) {
        updateTheme(id: $id, theme: $theme) {
          id
        }
      }
    `,
    variables: {
      id: params.id,
      theme: mapDataToThemeInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.updateTheme,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_ONE: return getOne(params)
    case GET_MANY: return getList(type, params)
    case CREATE: return create(params)
    case UPDATE: return update(params)
    default: return new Promise()
  }
}
