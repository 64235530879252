import React from 'react'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'

const HistoryField = ({ source }) => {
  const record = useRecordContext()
  if (!record) return null

  return (
    <div>
      { record[source].map((line, key) => {
        const [dateString, content] = line.split(' :: ')
        const date = new Date(dateString)

        return (<p key={ key }>
          {date.toLocaleString('fr-FR')}
          {' '}
          -
          {' '}
          { content }
        </p>)
      }) }
    </div>
  )
}

HistoryField.propTypes = {
  label: PropTypes.string.isRequired,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

HistoryField.defaultProps = {
  addLabel: true,
}

export default HistoryField
