import React from 'react'
import { Layout, AppBar, Menu } from 'react-admin'
import { Box, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

const CustomMenu = () =>
  <Menu>
    <Menu.ResourceItem name="theme" />
    <Menu.ResourceItem name="quiz" />
    <Menu.ResourceItem name="user" />
    <Menu.ResourceItem name="subscription" />
    <Menu.ResourceItem name="comment" />
    <Menu.ResourceItem name="product" />
    <Menu.ResourceItem name="taxRate" />
    <Menu.ResourceItem name="plan" />
    <Menu.ResourceItem name="coupon" />
    <Menu.ResourceItem name="pharmacy" />
    <Menu.ResourceItem name="ranking" />
    <Menu.ResourceItem name="reminder" />
    <Menu.ResourceItem name="dailyQuestion" />
    <Menu.ResourceItem name="accountDeletionRequest" />
    <Menu.Item to="/exports" primaryText="Exports" leftIcon={ <DownloadIcon /> } />
  </Menu>

const CustomAppBar = () =>
  <AppBar>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
  </AppBar>

export const CustomLayout = props => <Layout { ...props } appBar={ CustomAppBar } menu={ CustomMenu } />
