import { mapDataToEssentialInput } from 'data-mapper/essential'

export const mapDataToShortThemeInput = theme => ({
  id: theme.id,
})

export const mapDataToThemeInput = theme => ({
  id: theme.id,
  title: theme.title,
  essential: mapDataToEssentialInput(theme.essential),
})
