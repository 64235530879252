import React from 'react'
import { Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

const CreateTheme = props =>
  <Create { ...props }>
    <SimpleForm>
      <TextInput
        label="Nom du theme"
        source="title"
        validate={ required() }
        fullWidth
      />
    </SimpleForm>
  </Create>

export default CreateTheme
