import React from 'react'
import {
  AutocompleteInput,
  Create,
  DateInput,
  ReferenceInput,
  required,
  SimpleForm,
  useRedirect,
} from 'react-admin'
import { gql, useMutation } from '@apollo/client'

const CREATE_INTERNAL_SUBSCRIPTION = gql`
  mutation CreateInternalSubscription(
    $user: ID!,
    $startDate: String!,
    $endDate: String!
  ) {
    createInternalSubscription(
      user: $user,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
    }
  }
`

const CreateInternal = () => {
  const redirect = useRedirect()
  const [createInternalSubscription] = useMutation(CREATE_INTERNAL_SUBSCRIPTION)

  const handleSubmit = async (data) => {
    await createInternalSubscription({
      variables: {
        ...data,
      },
    })

    redirect('/subscription')
  }

  return (
    <Create
      resource="subscription"
    >
      <SimpleForm
        onSubmit={ handleSubmit }
      >
        <ReferenceInput
          label="Utilisateur"
          reference="user"
          source="user"
          perPage={ 25 }
        >
          <AutocompleteInput optionText="username" validate={ required() }/>
        </ReferenceInput>

        <DateInput
          label="Date de début"
          source="startDate"
          validate={ required() }
        />

        <DateInput
          label="Date de fin"
          source="endDate"
          validate={ required() }
        />
      </SimpleForm>
    </Create>
  )
}

export default CreateInternal
