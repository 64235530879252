import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  BooleanInput,
} from 'react-admin'

const CouponFilter = (props) => (
  <Filter { ... props }>
    <SearchInput
      source="search"
      alwaysOn
    />
    <BooleanInput
      label="Supprimé"
      source="isDeleted"
      alwaysOn
    />
  </Filter>
)

const CouponList = props =>
  <List
    { ...props }
    filters={ <CouponFilter /> }
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Nom du coupon"
        sortable={ false }
        source="name"
      />
      <TextField
        label="Pourcentage de reduction"
        sortable={ false }
        source="percentOff"
      />
      <TextField
        label="ID Stripe"
        source="stripeId"
      />
    </Datagrid>
  </List>

export default CouponList
