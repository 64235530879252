import { GET_LIST, GET_ONE, GET_MANY } from 'react-admin'
import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapGetManyVariables } from 'data-mapper'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
      query {
        allProducts {
          id
          title
          stripeId
        }
      }
    `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : null,
  })
  .then(({ data }) => ({
    data: data.allProducts,
    total: data.allProducts.length,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
      query ($id: ID!) {
        product(id: $id) {
          id
          title
          stripeId
        }
      }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.product,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_MANY: return getList(type, params)
    case GET_ONE: return getOne(params)
    default: return new Promise()
  }
}
