import { mapDataToAnswerInput } from 'data-mapper/answer'

export const mapDataToQuestionInput = question => ({
  id: question.id,
  title: question.title,
  topic: question.topic,
  type: question.type,
  answers: question.answers.map(mapDataToAnswerInput),
  feedback: question.feedback,
})
