import { mapDataToQuestionInput } from 'data-mapper/question'
import { dateToISOStringWithoutExtension } from 'utils/date'

export const mapDataToQuizInput = quiz => ({
  id: quiz.id,
  title: quiz.title,
  number: quiz.number ? Math.abs(quiz.number) : null,
  releaseDate: dateToISOStringWithoutExtension(quiz.releaseDate),
  themeId: quiz.theme.id,
  questions: quiz.questions.map(mapDataToQuestionInput),
})

export const mapDataToCreateQuizInput = quiz => ({
  id: quiz.id,
  title: quiz.title,
  number: quiz.number ? Math.abs(quiz.number) : null,
  releaseDate: dateToISOStringWithoutExtension(quiz.releaseDate),
  themeId: quiz.theme.id,
  questions: quiz.questions.map(mapDataToQuestionInput),
})

export const mapDataToEditQuizInput = quiz => ({
  title: quiz.title,
  number: quiz.number ? Math.abs(quiz.number) : null,
  releaseDate: dateToISOStringWithoutExtension(quiz.releaseDate),
  themeId: quiz.theme.id,
  questions: quiz.questions.map(mapDataToQuestionInput),
})
