import React from 'react'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/Download'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

const downloadExport = (type) => {
  fetch(`${process.env.REACT_APP_API_URL}/exports/${type}`, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${localStorage.getItem('authToken')}`,
    },
  })
    .then(res => res.blob())
    .then(data => {
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(data)
      a.download = `${type}-export.csv`
      a.click()
    })
}

const Export = () =>
  <>
    <Wrapper>
      <Stack spacing={ 2 }>
        <ExportButton variant='outlined' onClick={ () => downloadExport('dpc') }>
          <ExportIcon />
          <Typography noWrap>Export DPC</Typography>
        </ExportButton>
      </Stack>
    </Wrapper>
  </>

const ExportButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  cursor: 'pointer',
  maxWidth: 400,
}))

const ExportIcon = styled(DownloadIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const Wrapper = styled(Container)(({ theme }) => ({
  marginLeft: 0,
  paddingTop: theme.spacing(4),
}))

export default Export
