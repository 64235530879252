import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  BooleanInput,
} from 'react-admin'

const TaxRateFilter = (props) => (
  <Filter { ... props }>
    <BooleanInput
      label="Supprimé"
      source="isDeleted"
      alwaysOn
    />
  </Filter>
)

const TaxRateList = props =>
  <List
    { ...props }
    filters={ <TaxRateFilter /> }
  >
    <Datagrid bulkActionButtons={ false }>
      <TextField
        label="Intitulé"
        sortable={ false }
        source="name"
      />
      <TextField
        label="Pourcentage"
        sortable={ false }
        source="percentage"
      />
      <TextField
        label="ID Stripe"
        source="stripeId"
      />
    </Datagrid>
  </List>

export default TaxRateList
