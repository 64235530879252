import React from 'react'
import {
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SimpleForm,
  required,
} from 'react-admin'
import { useWatch } from 'react-hook-form'

const CreateSubscription = (props) => {
  const teamPlans = ['TEAM_SUBSCRIPTION', 'TEAM_SUBSCRIPTION_ANNUAL']

  const QuantityInput = () => {
    const plan = useWatch({ name: 'plan.id' })

    let licencesOptions = []

    for (let i = 2; i <= 100; i++) {
      licencesOptions.push({ id: i, name: i })
    }

    if (plan && teamPlans.includes(plan)) {
      return <SelectInput
        label="Nombre d'utilisateurs"
        source="quantity"
        choices={ licencesOptions }
        validate={ required() }
      />
    }
  }

  return (
    <>
      <Create { ...props }>
        <SimpleForm>
          <ReferenceInput
            label="Utilisateur"
            reference="user"
            source="user.id"
            perPage={ 25 }
          >
            <AutocompleteInput optionText="username" validate={ required() }/>
          </ReferenceInput>
          <SelectInput
            choices={ [
              { id: 'FREEDOM_SUBSCRIPTION', name: 'Abonnement mensuel' },
              { id: 'SOLO_SUBSCRIPTION', name: 'Abonnement annuel' },
              { id: 'TEAM_SUBSCRIPTION', name: 'Abonnement team mensuel' },
              { id: 'TEAM_SUBSCRIPTION_ANNUAL', name: 'Abonnement team annuel' },
            ] }
            label="Plan"
            source="plan.id"
            validate={ required() }
          />
          <QuantityInput />
          <NumberInput
            label="Période d'essai"
            source="trialPeriodDays"
          />
        </SimpleForm>
      </Create>
    </>
  )
}

export default CreateSubscription

