import React from 'react'
import {
  Edit,
  SimpleForm,
  required,
} from 'react-admin'
import { FormatButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text'
import { styled } from '@mui/material/styles'
import CustomToolbar from 'components/toolbar'

const DailyQuestionEdit = props =>
  <Edit { ...props }>
    <SimpleForm toolbar={ <CustomToolbar /> }>
      <RichTextInput
        label="Intitulé"
        source="title"
        toolbar={ <Root>
          <RichTextInputToolbar>
            <FormatButtons />
          </RichTextInputToolbar>
        </Root> }
        validate={ required() }
        fullWidth
      />
      <RichTextInput
        label="Answer"
        source="answer"
        toolbar={ <Root>
          <RichTextInputToolbar>
            <FormatButtons />
          </RichTextInputToolbar>
        </Root> }
        validate={ required() }
        fullWidth
      />
    </SimpleForm>
  </Edit>

const Root = styled('div')(() => ({
  alignSelf: 'flex-start',
  button: {
    display: 'none',
  },
  'button[aria-label="Bold"]': {
    display: 'block',
    borderRadius: 0,
  },
}))

export default DailyQuestionEdit
