import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE } from 'react-admin'
import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapDataToCreateQuizInput, mapDataToEditQuizInput } from 'data-mapper/quiz'
import { ALL_QUIZS } from 'components/user/UnlockQuiz'

const getList = params => apolloClient
  .query({
    query: gql`
      query ($page: Int!, $perPage: Int!, $filter: QuizFilter!) {
        allQuizzes(page: $page, perPage: $perPage, filter: $filter) {
          id
          title
          theme {
            id
            title
          }
          number
          releaseDate
        }
        _allQuizzesMeta(filter: $filter) {
          count
        }
      }
    `,
    variables: {
      ...params.pagination,
      filter: params.filter,
    },
  })
  .then(({ data }) => ({
    data: data.allQuizzes,
    total: data._allQuizzesMeta.count,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
      query ($id: ID!) {
        quiz(id: $id) {
          id
          title
          theme {
            id
            title
          }
          questions {
            id
            title
            topic
            type
            feedback
            answers {
              id
              title
              valid
              important
            }
          }
          number
          releaseDate
          rating
          statistics {
            avgScore
            avgTimeSpent
            sessionsCount
            completeSessionsCount
            importantCount
            validCount
            skippedCount
            ratingCount
            finishedInitialSessionCount
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.quiz,
  }))

const create = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($quiz: CreateQuizInput!) {
        createQuiz(quiz: $quiz) {
          id
        }
      }
    `,
    refetchQueries: [{
      query: ALL_QUIZS,
    }],
    variables: {
      quiz: mapDataToCreateQuizInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.createQuiz,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($id: ID!, $quiz: EditQuizInput!) {
        updateQuiz(id: $id, quiz: $quiz) {
          id
        }
      }
    `,
    refetchQueries: [{
      query: ALL_QUIZS,
    }],
    variables: {
      id: params.id,
      quiz: mapDataToEditQuizInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.updateQuiz,
  }))

const remove = params => apolloClient
  .mutate({
    mutation: gql`
    mutation ($id: ID!) {
      deleteQuiz(id: $id) {
        id
      }
    }
  `,
    refetchQueries: [{
      query: ALL_QUIZS,
    }],
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.deleteQuiz,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(params)
    case GET_ONE: return getOne(params)
    case CREATE: return create(params)
    case UPDATE: return update(params)
    case DELETE: return remove(params)
    default: return new Promise()
  }
}
