export const mapGetListVariables = params => ({
  ...params.pagination,
  sortField: params.sort.field,
  sortOrder: params.sort.order,
  filter: params.filter,
})

export const mapGetManyVariables = params => ({
  filter: {
    ids: params.ids,
  },
})
