import React from 'react'
import { Login } from 'react-admin'

const CustomLoginPage = () => (
  <Login
    // A random image that changes everyday
    backgroundImage="https://source.unsplash.com/random/1600x900/daily"
  />
)

export default CustomLoginPage
