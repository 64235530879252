import CustomToolbar from 'components/toolbar'
import React from 'react'
import {
  Edit,
  SimpleForm,
  BooleanInput,
} from 'react-admin'
const EditComment = props =>
  <Edit { ...props }>
    <SimpleForm toolbar={ <CustomToolbar /> }>
      <BooleanInput
        label="Remarque verifiée"
        source="checking"
      />
    </SimpleForm>
  </Edit>

export default EditComment

